#homepage-slider {
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 15px 0px;
  width: 100%; }
  #homepage-slider .swiper-slide a {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden; }
  #homepage-slider .swiper-slide img {
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
    height: auto;
    display: block; }
